import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "../../../../components/MDInput";
import Table from "@mui/material/Table";
import DataTableHeadCell from "../../../../examples/Tables/DataTable/DataTableHeadCell";
import TableRow from "@mui/material/TableRow";
import DataTableBodyCell from "../../../../examples/Tables/DataTable/DataTableBodyCell";
import TableBody from "@mui/material/TableBody";
import { useSnackbar } from "notistack";
import { restCall } from "../../../../api/api";
import ndjsonStream from "can-ndjson-stream";

function NetworkMissingShipments() {

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [warehousesEntries, setWarehousesEntries] = useState([]);
  const [warehouse, setWarehouse] = useState(null);

  const [loadingData, setLoadingData] = useState(false);

  const [data, setData] = useState([]);

  async function getWarehouses() {

    await restCall(process.env.REACT_APP_WAREHOUSES_ENDPOINT, "GET", null, null)
      .then(async response => {

        const ndjson = ndjsonStream(response.body);
        const reader = ndjson.getReader();
        let result = [];
        while(true) {
          const { done, value } = await reader.read();
          if(done) {
            break;
          }
          result = [...result, value];
          setWarehousesEntries(result);

          if(result.length === 1){
            setWarehouse(value);
          }
        }
        reader.releaseLock();

        // await getDriverWorkLists();
      })
      .catch(error => {
        enqueueSnackbar("Errore nel recupero delle filiali", { variant: "error" });
      });

  }

  function mapByArrivalWarehouse(list) {

    let map = new Map();

    for (let i = 0; i < list.length; i++) {

      if (map.has(list[i].arrivalWarehouseId)) {
        let value = map.get(list[i].arrivalWarehouseId);

        value.push(list[i]);

        map.set(list[i].arrivalWarehouseId, value);
      } else {
        let value = [];

        value.push(list[i]);

        map.set(list[i].arrivalWarehouseId, value);
      }

    }

    return map;

  }

  function mapByDestinationWarehouse(mapByArrivalWarehouse){

    //aaaaaaaa1 //TODO 123flakjs

  }



  useEffect(()=>{

    getWarehouses();

  }, []);

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            Mancanze da network
          </MDTypography>
          <MDBox display="flex" alignItems="center" lineHeight={0}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>{data.length}</strong> spedizioni mancanti
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox>
          <Autocomplete
            getOptionLabel={option => option.code + " - " + option.description}
            value={warehouse}
            options={warehousesEntries}
            onChange={(event, warehouseValue) => {
              setWarehouse(warehouseValue);
            }}
            size="small"
            sx={{ width: "15rem"}}
            renderInput={(params) => <MDInput {...params}
                                              label={"Filiale"}
                                              variant="outlined"
                                              margin="dense"
                                              value={warehouse}
                                              defaultValue={warehouse}
            />}
          />
        </MDBox>
      </MDBox>
      <MDBox>
        <TableContainer sx={{ boxShadow: "none" }}>
          <Table>
            <MDBox component="thead">
              <DataTableHeadCell
                key={1}
                width={"auto"}
                align={"left"}
                sorted={false}
              >
                {"ID"}
              </DataTableHeadCell>
              <DataTableHeadCell
                key={2}
                width={"auto"}
                align={"left"}
                sorted={false}
              >
                {"Contratto"}
              </DataTableHeadCell>
              <DataTableHeadCell
                key={3}
                width={"auto"}
                align={"left"}
                sorted={false}
              >
                {"Riferimento"}
              </DataTableHeadCell>
              <DataTableHeadCell
                key={4}
                width={"auto"}
                align={"left"}
                sorted={false}
              >
                {"Data"}
              </DataTableHeadCell>
            </MDBox>
            <TableBody>
              {data.map((row, key) => {
                return (
                  <TableRow key={key}>
                    <DataTableBodyCell
                      noBorder={data.length - 1 === key}
                      align={"left"}
                    >
                      {row.id}
                    </DataTableBodyCell>
                    <DataTableBodyCell
                      noBorder={data.length - 1 === key}
                      align={"left"}
                    >
                      {row.contract}
                    </DataTableBodyCell>
                    <DataTableBodyCell
                      noBorder={data.length - 1 === key}
                      align={"left"}
                    >
                      {row.reference}
                    </DataTableBodyCell>
                    <DataTableBodyCell
                      noBorder={data.length - 1 === key}
                      align={"left"}
                    >
                      {row.date.toLocaleDateString()}
                    </DataTableBodyCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </MDBox>
    </Card>
  );
}
export default NetworkMissingShipments;