import { forwardRef } from "react";

import PropTypes from "prop-types";
import OperationsProgressRoot from "./OperationsProgressRoot";
import MDTypography from "../MDTypography";

const OperationsLoading = forwardRef(({ color, size, ...rest }, ref) => (
  <>
    <OperationsProgressRoot
      {...rest}
      ref={ref}
      size={size}
      color={color}
      variant="indeterminate"
      ownerState={{color, size}}
    />
  </>
));

OperationsLoading.defaultProps = {
  color: "primary",
  size: 150
};

OperationsLoading.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  size: PropTypes.number
};

export default OperationsLoading;
