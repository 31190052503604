import { useEffect, useState } from "react";
import { restCall } from "../api/api";
import ndjsonStream from "can-ndjson-stream";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/it";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Progress from "./Progress";
import { CircularProgress, Select, TableCell } from "@mui/material";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { enqueueSnackbar } from "notistack";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

function PromisedToShip(props) {

  const columns = [
    { Header: "Cliente", accessor: "client", align: "left" },
    { Header: "Tariffa attiva", accessor: "activeRate", align: "left" },
    { Header: "Volume attuale", accessor: "actualVolume", align: "right" },
    { Header: "Volume promesso", accessor: "promisedVolume", align: "right" },
    { Header: "Status", accessor: "status", align: "center" }
  ];

  const [selectedDate, setSelectedDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);

  const [agent, setAgent] = useState(null);
  const [agents, setAgents] = useState([]);

  async function getAgents() {

    setAgents([]);

    await restCall(process.env.REACT_APP_AGENTS_ENDPOINT, "GET", null, null, props.operatorApiKey)
      .then(async response => {
          const ndjson = ndjsonStream(response.body);
          const reader = ndjson.getReader();
          let result = [];
          while (true) {
            const { done, value } = await reader.read();
            if (done) {
              if(result.length === 1){
                setAgent(result[0]);
              }
              break;
            }
            result = [...result, value];

            //setData(result);
            setAgents(result);
          }
          reader.releaseLock();
          await setLoading(false);
        }
      ).catch(async error => {
        enqueueSnackbar("Errore nel recupero dei dati", { variant: "error" });
        await setLoading(false);
      });

  }


  async function getData() {

    setLoading(true);

    let queryParams = {};

    if (selectedDate) {
      queryParams["month"] = new Date(selectedDate).getMonth() + 1;
      queryParams["year"] = new Date(selectedDate).getFullYear();
    }

    if (agent) {
      queryParams["agent-ids"] = agent.id;
    }

    //setData([]);
    setRows([]);

    await restCall(process.env.REACT_APP_ACTIVE_RATES_ENDPOINT + "/promised-volume-report", "GET", null, queryParams, props.operatorApiKey)
      .then(async response => {
          const ndjson = ndjsonStream(response.body);
          const reader = ndjson.getReader();
          let result = [];
          while (true) {
            const { done, value } = await reader.read();
            if (done) {
              break;
            }
            result = [...result, value];

            //setData(result);
            setRows(result);
          }
          reader.releaseLock();
          await setLoading(false);
        }
      ).catch(async error => {
        enqueueSnackbar("Errore nel recupero dei dati", { variant: "error" });
        await setLoading(false);
      });

  }

  useEffect(() => {
    getAgents();
  }, []);

  useEffect(() => {
    if (null != selectedDate) {
      getData();
    }
  }, [selectedDate, agent]);

  return (
    // <DashboardLayout>
      <div>
        <Box pt={6} pb={3}>
          <Box display={"flex"}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs} adapterLocale="it">
              <DatePicker
                autoOk
                disableToolbar
                label={"Mese/Anno"}
                openTo="month"
                views={["month", "year"]}
                format={"MMMM/YYYY"}
                margin={"normal"}
                inputVariant="outlined"
                value={selectedDate}
                error={!selectedDate}
                required={true}
                onAccept={event => setSelectedDate(event)}
                // onChange={event => setSelectedDate(event)}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
              />
            </LocalizationProvider>
          </Box>
          <Box display={"flex"} mt={"2%"}>
            <Autocomplete
              onOpen={getAgents}
              value={agent}
              getOptionLabel={option => option.code + " - " + option.name + " " + option.surname}
              options={agents}
              onChange={(event, newValue) => {
                setAgent(newValue);
              }}
              sx={{ width: "20%" }}
              renderInput={(params) => <TextField {...params}
                                                  label={"Agente"}
                                                  variant={"outlined"}
                                                  size={"small"}
                                                  margin={"dense"}
                                                  value={agent}
                                                  defaultValue={agent}
              />}
            />
          </Box>
        </Box>
        {loading ?
          <Box display="flex" alignItems="center" lineHeight={0}>
            <CircularProgress color="primary" size={97} style={{ margin: "auto", marginBlock: "5%" }} />
          </Box>
          :
          <>
            {rows.length > 0 ?
              <Box>
                {/*<DataTable*/}
                {/*  table={{ columns, rows }}*/}
                {/*  isSorted={false}*/}
                {/*  entriesPerPage={false}*/}
                {/*  showTotalEntries={false}*/}
                {/*  noEndBorder*/}
                {/*/>*/}
                <TableContainer sx={{ boxShadow: "none" }}>
                  <Table>
                    <Box component="thead">
                      <TableRow>
                        {columns.map((item, idx) => (
                          <TableCell
                            key={idx}
                            width={item.width ? item.width : "auto"}
                            align={item.align ? item.align : "left"}
                          >
                            {item.Header}
                          </TableCell>
                        ))}
                      </TableRow>
                    </Box>
                    <TableBody>
                      {rows.map((item, key) => {
                        return (
                          <TableRow key={key}>
                            <TableCell>
                              <Typography component="span" variant="caption" color="grey" fontWeight="medium">
                                {item.customerCode}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography component="span" variant="caption" color="grey" fontWeight="medium">
                                {item.activeRateCode}
                              </Typography>
                            </TableCell>
                            <TableCell align={"right"}>
                              <Typography component="span" variant="caption" color="grey" fontWeight="medium">
                                {item.actualVolume}
                              </Typography>
                            </TableCell>
                            <TableCell align={"right"}>
                              <Typography component="span" variant="caption" color="grey" fontWeight="medium">
                                {item.promisedVolume}
                              </Typography>
                            </TableCell>
                            <TableCell align={"center"}>
                              <Box width="100%" textAlign="left">
                                <Progress percentageValue={((item.actualVolume / item.promisedVolume) * 100).toFixed(0)}
                                          value={Math.min((item.actualVolume / item.promisedVolume) * 100, 100)}
                                          color={item.actualVolume < item.promisedVolume ? "error" : "success"}
                                          variant="gradient"
                                          label={true} />
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              :
              <Box>
                <Typography margin="auto" fontSize={18} fontWeight="regular" color="primary">
                  {"Nessun risultato"}
                </Typography>
              </Box>
            }
          </>
        }
      </div>
    // </DashboardLayout>
  );
}

export default PromisedToShip;