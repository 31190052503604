import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import OpenDriverWorkLists from "./components/OpenDriverWorkLists";
import NetworkMissingShipments from "./components/NetworkMissingShipments";
import { useState } from "react";
import { SettingsRounded } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import DashboardConfigurator from "./components/DashboardConfigurator";

function Dashboard() {

  const [configuration, setConfiguration] = useState(
    {
      openDriverWorkList: true,
      networkMissingShipment: true
    }
  )

  const [openConfig, setOpenConfig] = useState(false);

  function confirmChanges(configuration){
    setConfiguration(configuration);
  }

  return (
    <DashboardLayout>
        {/*<DashboardNavbar />*/}
      <DashboardConfigurator open={openConfig}
                             onClose={()=>setOpenConfig(false)}
                             confirmChanges={confirmChanges}
                             config={configuration}
      />
      <MDBox py={3}>
        {/*<Grid container spacing={3}>*/}
        {/*  <Grid item xs={12} md={6} lg={3}>*/}
        {/*    <MDBox mb={1.5}>*/}
        {/*      <ComplexStatisticsCard*/}
        {/*        color="dark"*/}
        {/*        icon="weekend"*/}
        {/*        title="Bookings"*/}
        {/*        count={281}*/}
        {/*        percentage={{*/}
        {/*          color: "success",*/}
        {/*          amount: "+55%",*/}
        {/*          label: "than lask week",*/}
        {/*        }}*/}
        {/*      />*/}
        {/*    </MDBox>*/}
        {/*  </Grid>*/}
        {/*  <Grid item xs={12} md={6} lg={3}>*/}
        {/*    <MDBox mb={1.5}>*/}
        {/*      <ComplexStatisticsCard*/}
        {/*        icon="leaderboard"*/}
        {/*        title="Today's Users"*/}
        {/*        count="2,300"*/}
        {/*        percentage={{*/}
        {/*          color: "success",*/}
        {/*          amount: "+3%",*/}
        {/*          label: "than last month",*/}
        {/*        }}*/}
        {/*      />*/}
        {/*    </MDBox>*/}
        {/*  </Grid>*/}
        {/*  <Grid item xs={12} md={6} lg={3}>*/}
        {/*    <MDBox mb={1.5}>*/}
        {/*      <ComplexStatisticsCard*/}
        {/*        color="success"*/}
        {/*        icon="store"*/}
        {/*        title="Revenue"*/}
        {/*        count="34k"*/}
        {/*        percentage={{*/}
        {/*          color: "success",*/}
        {/*          amount: "+1%",*/}
        {/*          label: "than yesterday",*/}
        {/*        }}*/}
        {/*      />*/}
        {/*    </MDBox>*/}
        {/*  </Grid>*/}
        {/*  <Grid item xs={12} md={6} lg={3}>*/}
        {/*    <MDBox mb={1.5}>*/}
        {/*      <ComplexStatisticsCard*/}
        {/*        color="primary"*/}
        {/*        icon="person_add"*/}
        {/*        title="Followers"*/}
        {/*        count="+91"*/}
        {/*        percentage={{*/}
        {/*          color: "success",*/}
        {/*          amount: "",*/}
        {/*          label: "Just updated",*/}
        {/*        }}*/}
        {/*      />*/}
        {/*    </MDBox>*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
        {/*<MDBox mt={4.5}>*/}
        {/*  <Grid container spacing={3}>*/}
        {/*    <Grid item xs={12} md={6} lg={4}>*/}
        {/*      <MDBox mb={3}>*/}
        {/*        <ReportsBarChart*/}
        {/*          color="info"*/}
        {/*          title="website views"*/}
        {/*          description="Last Campaign Performance"*/}
        {/*          date="campaign sent 2 days ago"*/}
        {/*          chart={reportsBarChartData}*/}
        {/*        />*/}
        {/*      </MDBox>*/}
        {/*    </Grid>*/}
        {/*    <Grid item xs={12} md={6} lg={4}>*/}
        {/*      <MDBox mb={3}>*/}
        {/*        <ReportsLineChart*/}
        {/*          color="success"*/}
        {/*          title="daily sales"*/}
        {/*          description={*/}
        {/*            <>*/}
        {/*              (<strong>+15%</strong>) increase in today sales.*/}
        {/*            </>*/}
        {/*          }*/}
        {/*          date="updated 4 min ago"*/}
        {/*          chart={sales}*/}
        {/*        />*/}
        {/*      </MDBox>*/}
        {/*    </Grid>*/}
        {/*    <Grid item xs={12} md={6} lg={4}>*/}
        {/*      <MDBox mb={3}>*/}
        {/*        <ReportsLineChart*/}
        {/*          color="dark"*/}
        {/*          title="completed tasks"*/}
        {/*          description="Last Campaign Performance"*/}
        {/*          date="just updated"*/}
        {/*          chart={tasks}*/}
        {/*        />*/}
        {/*      </MDBox>*/}
        {/*    </Grid>*/}
        {/*  </Grid>*/}
        {/*</MDBox>*/}
        <MDBox>
          <Grid container columns={2} spacing={3}>
            {configuration.openDriverWorkList && <Grid item xs={12} md={12} lg={true}>
              <OpenDriverWorkLists />
            </Grid>
            }
            {
              configuration.networkMissingShipment && <Grid item xs={12} md={12} lg={true}>
                <NetworkMissingShipments />
              </Grid>
            }
          </Grid>
        </MDBox>
      </MDBox>
      <MDBox
       position={"fixed"}
       bottom={"1em"}
       right={"1em"}
      >
        <IconButton
          onClick={()=>setOpenConfig(true)}
        >
          <SettingsRounded/>
        </IconButton>
      </MDBox>
      {/*<Footer />*/}
    </DashboardLayout>
  );
}

export default Dashboard;
