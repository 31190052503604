import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import MDBox from "../../components/MDBox";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "../../components/MDInput";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { cachedRestCall, restCall } from "../../api/api";
import ndjsonStream from "can-ndjson-stream";
import InfoCard from "./infoCard";
import Grid from "@mui/material/Grid";

function Distribution() {

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [warehouses, setWarehouses] = useState([]);
  const [warehouse, setWarehouse] = useState(null);
  const [loadingWarehouses, setLoadingWarehouses] = useState(false);

  const [drivers, setDrivers] = useState([]);
  const [driver, setDriver] = useState(null);
  const [loadingDrivers, setLoadingDrivers] = useState(false);

  const [suppliers, setSuppliers] = useState([]);

  const [truckLoads, setTruckLoads] = useState([]);
  const [loadingTruckLoads, setLoadingTruckLoads] = useState(false);


  const [driversMap, setDriversMap] = useState([]);

  async function getWarehouses() {

    const data = await cachedRestCall(process.env.REACT_APP_WAREHOUSES_ENDPOINT, "GET", null, null, true)
      .catch(error => {
        enqueueSnackbar("Errore nel recupero delle filiali", { variant: "error" });
      });

    setWarehouses(data);
  }

  async function getDrivers() {

    setLoadingDrivers(true);

    let queryParams = {};

    if (warehouse) {
      queryParams["warehouse-id"] = warehouse.id;
    }

    setDrivers([]);

    await restCall(process.env.REACT_APP_DRIVERS_ENDPOINT, "GET", null, queryParams, false)
      .then(async response => {

        const ndjson = ndjsonStream(response.body);
        const reader = ndjson.getReader();
        let result = [];
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            break;
          }
          result = [...result, value];

          await getSupplier(value.supplierID);

          setDrivers(result);
        }
        reader.releaseLock();
        await setLoadingDrivers(false);
      })
      .catch(async error => {
        enqueueSnackbar("Errore nel recupero degli autisti", { variant: "error" });
        await setLoadingDrivers(false);
      });


  }


  async function getSupplier(supplierId) {

    const supplier = await cachedRestCall(process.env.REACT_APP_SUPPLIERS_ENDPOINT + `/${supplierId}`, "GET", null, null, false);

    if (!suppliers.find(item => item.id === supplier[0].id)) {

      let newSuppliers = suppliers;

      newSuppliers.push(supplier[0]);

      setSuppliers(newSuppliers);
    }

  }


  async function getDriver(driverId){

    const driver = await cachedRestCall(process.env.REACT_APP_DRIVERS_ENDPOINT + `/${driverId}`, "GET", null, null, false);

    if (!driversMap.find(item => item.id === driver[0].id)) {

      let newDrivers = driversMap;

      newDrivers.push(driver[0]);

      setDriversMap(newDrivers);
    }

    return driver[0];

  }

  async function getTruckLoadsInfo(){

    let queryParams = {};

    if(warehouse){
      queryParams["warehouse-ids"] = [warehouse.id];
    }

    await restCall(process.env.REACT_APP_WORKLIST_ENDPOINT+  "/driver-truck-load", "GET", null, queryParams, false)
      .then(async response => {

        const ndjson = ndjsonStream(response.body);
        const reader = ndjson.getReader();
        let result = [];
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            break;
          }

          let driver = await getDriver(value.driverId);

          value.driverName = driver.name;
          value.driverSurname = driver.surname;

          result = [...result, value];



          setTruckLoads(result);
        }
        reader.releaseLock();
        await setLoadingTruckLoads(false);
      })
      .catch(async error => {
        enqueueSnackbar("Errore nel recupero dei carichi vettura", { variant: "error" });
        await setLoadingTruckLoads(false);
      });


  }

  useEffect( () => {
    if(warehouse){
       getTruckLoadsInfo();
    }
  }, [warehouse]);

  return (
    <DashboardLayout>
      <MDBox pt={6} pb={3}>
        <Autocomplete
          getOptionLabel={option => option.code + " " + option.description}
          value={warehouse}
          options={warehouses}
          onChange={(event, warehouseValue) => {
            setDriver(null);
            setTruckLoads([]);
            setWarehouse(warehouseValue);
          }}
          onOpen={getWarehouses}
          size="small"
          sx={{ width: "15rem" }}
          renderInput={(params) => <MDInput {...params}
                                            label={"Filiale"}
                                            variant="outlined"
                                            margin="dense"
                                            value={warehouse}
                                            defaultValue={warehouse}
          />}
        />

        <Autocomplete
          loading={loadingDrivers}
          disabled={!warehouse}
          getOptionLabel={option => option.name + " - " + option.surname}
          groupBy={(option) => suppliers.find(supplier => supplier.id === option.supplierID)?.accountCorporateCode}
          value={driver}
          options={drivers.sort((a, b) => a.supplierID - b.supplierID)}
          onChange={(event, driver) => {
            setDriver(driver);
          }}
          onOpen={getDrivers}
          size="small"
          sx={{ width: "15rem" }}
          renderInput={(params) => <MDInput {...params}
                                            label={"Autista"}
                                            variant="outlined"
                                            margin="dense"
                                            value={driver}
                                            defaultValue={driver}
          />}
        />
      </MDBox>
      <MDBox>
        <Grid container spacing={5}>
              {truckLoads
                .filter(value => driver? driver.id === value.driverId : value)
                .map(item => {

                return <Grid item xs={12} md={12} xl={3}>
                  <InfoCard item={item}/>
                </Grid>;

              })}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Distribution;