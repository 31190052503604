import { CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";


export default styled(CircularProgress)(({theme, ownerState}) => {
  const {palette, functions} = theme;
  const {color, size} = ownerState;

  let backgroundValue = palette[color] ? palette[color].main : palette.info.main;

  return {
    ".MuiCircularProgress-circle": {
      background: backgroundValue,
      size: size,
      color: color
    },
  };

})