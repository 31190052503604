import { Button, Checkbox, Dialog, FormControlLabel, ToggleButton, ToggleButtonGroup } from "@mui/material";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import Card from "@mui/material/Card";
import { useEffect, useState } from "react";
import DataTableBodyCell from "../../../../examples/Tables/DataTable/DataTableBodyCell";
import MDButton from "../../../../components/MDButton";


function LaptopIcon() {
  return null;
}

function DashboardConfigurator(props) {

  const [configuration, setConfiguration] = useState(props.config);
  const [list, setList] = useState([]);

  function handleConfiguration(event, newList) {
    setList(newList);
  }

  function confirmChanges() {

    let newConfig = Array.from(Object.keys(configuration));

    for (let i = 0; i < newConfig.length; i++) {

      configuration[newConfig[i]] = list.some(name => name === newConfig[i]);
    }

    props.confirmChanges(configuration);

    props.onClose();

  }

  function close() {

    props.onClose();

    setTimeout(() => {
      setConfiguration(props.config);
    }, 500);
  }

  useEffect(() => {

    setList(Array.from(Object.keys(configuration).map(row => {
      return row;
    })));

  }, []);

  return <Dialog

    open={props.open}
    onClose={close}
  >
    <Card>
      <MDBox m={"auto"} display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDTypography variant="h6" gutterBottom>
          Configurazione Widget
        </MDTypography>
      </MDBox>
      <MDBox m={"auto"} mb={"5%"}>
        <ToggleButtonGroup
          orientation={"vertical"}
          value={list}
          onChange={handleConfiguration}
        >
          {Object.keys(configuration).map((row, key) => {
            return <ToggleButton value={row} aria-label="laptop">
              <MDTypography>
                <strong>{row}</strong>
              </MDTypography>
            </ToggleButton>;
          })}
        </ToggleButtonGroup>
        {/*<TableContainer sx={{boxShadow: "none"}}>*/}
        {/*  <Table>*/}
        {/*    <TableBody>*/}
        {/*      {Object.keys(configuration).map((row, key) => {*/}
        {/*        return (*/}
        {/*          <TableRow key={key}>*/}
        {/*            <DataTableBodyCell*/}
        {/*              key={key}*/}
        {/*              noBorder={true}*/}
        {/*              align={"left"}*/}
        {/*            >*/}
        {/*              <FormControlLabel*/}
        {/*                control={<Checkbox*/}
        {/*                  checked={configuration[row]}*/}
        {/*                  onChange={((event, checked) => handleCheckChange(checked, row))}*/}
        {/*                />}*/}
        {/*                label={row}*/}
        {/*                labelPlacement={"end"}*/}

        {/*              />*/}
        {/*            </DataTableBodyCell>*/}

        {/*          </TableRow>*/}
        {/*        );*/}
        {/*      })}*/}
        {/*    </TableBody>*/}
        {/*  </Table>*/}
        {/*</TableContainer>*/}
      </MDBox>
      <MDBox m={"auto"} mb={"5%"}>
        <MDButton variant={"gradient"} color={"primary"} onClick={confirmChanges}>{"Save changes"}</MDButton>
      </MDBox>
    </Card>
  </Dialog>;


}

export default DashboardConfigurator;