import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";


export default function Progress(props){

  return <>
    {props.label && (
      <Typography variant="button" fontWeight="medium" color="text">
        {props.percentageValue}%
      </Typography>
    )}
    <LinearProgress
      variant="determinate"
      value={props.value}
      color={props.color}
    />
  </>


}