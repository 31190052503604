import Card from "@mui/material/Card";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import MDButton from "../../../components/MDButton";
import { useEffect, useState } from "react";
import { Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { restCall } from "../../../api/api";


function InfoCard(props) {

  const [openConfirmDDT, setOpenConfirmDDT] = useState(false);
  const [delayed, setDelayed] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [delayedDate, setDelayedDate] = useState(null);
  const [delayedTime, setDelayedTime] = useState(null);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const handleDelayedValue = (event) => setDelayed(event.target.checked);

  async function confirmDDT() {

    let input = {};

    input.driverId = props.item.driverId;
    input.onlyConfirmedParcels = tabValue === 1;
    input.delayedWorkList = delayed;

    if (delayedDate && delayedTime) {
      input.delayedWorkListDateTime = moment(delayedDate.toLocaleDateString() + ' ' + delayedTime.toLocaleTimeString(), 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ssZ');
    }

    await restCall(process.env.REACT_APP_WORKLIST_ENDPOINT, "POST", input, null)
      .then(result => {

      })
      .catch(error => {

      })


  }

  useEffect(() => {


  }, []);

  return <Card>
    <MDBox p={2} mx={1} display="flex" justifyContent="left">
      <Avatar sx={{ width: 80, height: 80 }} fontSize="">{props.item.avatar}</Avatar>
      <MDTypography mx={4} variant="h4" fontWeight="medium" textTransform="capitalize">
        {props.item.driverName + " " + props.item.driverSurname}
      </MDTypography>
    </MDBox>
    <MDBox pb={2} px={2} textAlign="center">
      <MDTypography variant={"h5"} fontWeight="medium" textTransform="capitalize">
        {"Colli " + props.item.numOfConfirmedParcels + "/" + props.item.numOfParcels}
      </MDTypography>
    </MDBox>
    <MDBox pb={2} px={2} textAlign="center">
      <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
        {"Ritiri " + props.item.numOfPickups}
      </MDTypography>
    </MDBox>
    <Divider />
    <MDBox pb={2} px={2} display={"flex"}>
      <MDButton
        style={{ margin: "auto" }}
        color={"dark"}
        variant="outlined"
      >
        Dettagli
      </MDButton>
      <MDButton
        style={{ margin: "auto" }}
        color={"dark"}
        variant="outlined"
        onClick={() => setOpenConfirmDDT(true)}
      >
        DDT
      </MDButton>
    </MDBox>
    <Dialog
      maxWidth={"sm"}
      fullWidth
      open={openConfirmDDT}
      onClose={() => setOpenConfirmDDT(false)}
    >

      <DialogTitle>
        <MDBox textAlign="center">
          <MDTypography variant="h4" fontWeight="medium" textTransform="capitalize">
            {"Opzioni di conferma DDT"}
          </MDTypography>
        </MDBox>
      </DialogTitle>
      <DialogContent>
        <MDBox px={2} textAlign="center">
          <MDTypography variant="h6" fontWeight="regular" textTransform="capitalize">
            {"Colli confermati: " + props.item.numOfConfirmedParcels + "/" + props.item.numOfParcels}
          </MDTypography>
        </MDBox>
        <Divider />
        <Tabs style={{ backgroundColor: "lightgrey", width: "50%", margin: "auto" }} orientation={"vertical"}
              value={tabValue} onChange={handleSetTabValue}>
          <Tab
            value={0}
            label="Includi tutti i colli"
          />
          <Tab
            value={1}
            label="Includi solo i colli confermati"
          />
        </Tabs>
        <FormControlLabel
          style={{ width: "fit-content", margin: "auto", marginBlock: "5%" }}
          value={delayed}
          control={<Checkbox color={"primary"} />}
          label="Giornaliera differita"
          labelPlacement="right"
          onChange={handleDelayedValue}
        />

        {delayed && (
          <>
            <MDBox pb={2} px={2} display={"flex"} style={{width: "fit-content", margin: "auto" }}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}>
                <DatePicker
                  autoOk
                  disableToolbar
                  format={"DD/MM/YYYY"}
                  // margin="normal"
                  margin={"normal"}
                  inputVariant="outlined"
                  label={"Data"}
                  size={"small"}
                  disablePast={true}
                  value={delayedDate}
                  error={!delayedDate}
                  required={true}
                  onChange={event => setDelayedDate(event)}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </LocalizationProvider>
            </MDBox>
            <MDBox pb={2} px={2} display={"flex"} style={{width: "fit-content", margin: "auto" }}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}>
                <TimePicker
                  style={{margin: "auto" }}
                  label={"Orario"}
                  disableOpenPicker={true}
                  format={"hh:mm"}
                  onChange={event => setDelayedTime(event)}
                  value={delayedTime}
                  validationError={!delayedTime}
                  renderInput={(props) => <TextField {...props} />}
                  openPicker={true} rawValue={delayedTime} />

              </LocalizationProvider>
            </MDBox>
          </>
      )}

    </DialogContent>

    <Divider />
    <MDBox pb={2} px={2} display={"flex"}>
      <MDButton
        style={{ margin: "auto" }}
        color={"error"}
        variant="outlined"
        onClick={() => {
          setOpenConfirmDDT(false);
          setTimeout(()=>{
            setDelayed(false);
          }, 200)
        }}
      >
        Annulla
      </MDButton>
      <MDButton
        style={{ margin: "auto" }}
        color={"success"}
        variant="outlined"

      >
        Conferma
      </MDButton>
    </MDBox>

  </Dialog>
</Card>
}

export default InfoCard;