import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { ControllerProvider } from "context";
import { closeSnackbar, SnackbarProvider } from "notistack";
import IconButton from "@mui/material/IconButton";
import { CloseOutlined } from "@mui/icons-material";

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <SnackbarProvider maxSnack={4} persist  anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }} action={(snackbarId) => (
      <IconButton onClick={() => closeSnackbar(snackbarId)}>
        <CloseOutlined/>
      </IconButton>
    )}>
      <ControllerProvider>
        <App />
      </ControllerProvider>
    </SnackbarProvider>
  </BrowserRouter>
);
