import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "../../../../components/MDInput";
import { restCall } from "../../../../api/api";
import MDButton from "../../../../components/MDButton";
import { Dialog, Table } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import DataTableHeadCell from "../../../../examples/Tables/DataTable/DataTableHeadCell";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import DataTableBodyCell from "../../../../examples/Tables/DataTable/DataTableBodyCell";
import { InfoOutlined } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import OperationsLoading from "../../../../components/OperationsLoading";
import ndjsonStream from "can-ndjson-stream";


function OpenDriverWorkLists() {

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [warehousesEntries, setWarehousesEntries] = useState([]);

  const [loadingData, setLoadingData] = useState(false);

  const [warehouse, setWarehouse] = useState(null);

  const [data, setData] = useState([]);

  const [openWarehouseDetails, setOpenWarehouseDetails] = useState(false);
  const [active, setActive] = useState(null);

  async function getDriverWorkLists() {

    setLoadingData(true);

    let queryParams = {};
    queryParams.status = "open";

    if (warehouse) {
      queryParams.warehouseId = warehouse.id;
    }

    await restCall(process.env.REACT_APP_WORKLIST_ENDPOINT, "GET", null, queryParams)
      .then(response => response.json())
      .then(response => {
        setData(response);
      })
      .catch(error => {
        enqueueSnackbar("Errore nel recupero delle giornaliere", { variant: "error" });
      })
      .finally(() => {
        setTimeout(()=>{
          setLoadingData(false);
        }, 1000)

      });


  }

  async function getWarehouses() {

    await restCall(process.env.REACT_APP_WAREHOUSES_ENDPOINT, "GET", null, null)
      .then(async response => {

        const ndjson = ndjsonStream(response.body);
        const reader = ndjson.getReader();
        let result = [];
        while(true) {
          const { done, value } = await reader.read();
          if(done) {
            break;
          }
          result = [...result, value];
          setWarehousesEntries(result);

          if(result.length === 1){
            setWarehouse(value);
          }
        }
        reader.releaseLock();

        // await getDriverWorkLists();
      })
      .catch(error => {
        enqueueSnackbar("Errore nel recupero delle filiali", { variant: "error" });
      });

  }

  function mapByWarehouse(list) {

    let map = new Map();

    for (let i = 0; i < list.length; i++) {

      if (map.has(list[i].warehouseId)) {
        let value = map.get(list[i].warehouseId);

        value.push(list[i]);

        map.set(list[i].warehouseId, value);
      } else {
        let value = [];

        value.push(list[i]);

        map.set(list[i].warehouseId, value);
      }

    }

    return map;

  }

  useEffect(() => {
    getDriverWorkLists();
  }, [warehouse]);


  useEffect(() => {

    getWarehouses();

  }, []);

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            Giornaliere Aperte
          </MDTypography>
        </MDBox>
        <MDBox>
          <Autocomplete
            getOptionLabel={option => option.code + " - " + option.description}
            value={warehouse}
            options={warehousesEntries}
            onChange={(event, warehouseValue) => {
              setWarehouse(warehouseValue);
            }}
            size="small"
            sx={{ width: "15rem" }}
            renderInput={(params) => <MDInput {...params}
                                              label={"Filiale"}
                                              variant="outlined"
                                              margin="dense"
                                              value={warehouse}
                                              defaultValue={warehouse}
            />}
          />
        </MDBox>

      </MDBox>
      {
        loadingData ?
          <MDBox display="flex" alignItems="center" lineHeight={0}>
            <OperationsLoading color="primary" size={97} style={{margin: "auto", marginBlock: "5%"}}/>
          </MDBox>
          :
          <MDBox>
            <MDBox display="flex" alignItems="center" lineHeight={0}>
              <MDButton id={"button"} onClick={() => setOpenWarehouseDetails(true)} style={{ margin: "auto" }}
                        variant={"text"}>
                <MDTypography margin="auto" fontSize={80} fontWeight="regular" color="primary">
                  <strong>{data.length}</strong>
                </MDTypography>
              </MDButton>
            </MDBox>
            <Dialog open={openWarehouseDetails} onClose={() => setOpenWarehouseDetails(false)}>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <TableContainer sx={{ boxShadow: "none" }}>
                  <Table>
                    <MDBox component="thead">
                      <DataTableHeadCell
                        key={1}
                        width={"auto"}
                        align={"left"}
                        sorted={false}
                      >
                        {"Codice Filiale"}
                      </DataTableHeadCell>
                      <DataTableHeadCell
                        key={1}
                        width={"auto"}
                        align={"left"}
                        sorted={false}
                      >
                        {"Numero Giornaliere Aperte"}
                      </DataTableHeadCell>
                      <DataTableHeadCell
                        key={1}
                        width={"auto"}
                        align={"left"}
                        sorted={false}
                      >
                      </DataTableHeadCell>
                    </MDBox>
                    <TableBody>
                      {[...mapByWarehouse(data).entries()].map(([key, value]) => {
                        return (
                          <TableRow key={key}>
                            <DataTableBodyCell
                              noBorder={data.length - 1 === key}
                              align={"center"}
                            >
                              {key}
                            </DataTableBodyCell>
                            <DataTableBodyCell
                              noBorder={data.length - 1 === key}
                              align={"center"}
                            >
                              <strong>{value.length}</strong>
                            </DataTableBodyCell>
                            <DataTableBodyCell
                              noBorder={data.length - 1 === key}
                              align={"center"}
                            >
                              <MDButton id={key} onClick={() => setActive(key)} style={{ margin: "auto" }}
                                        variant={"text"} color={"info"}>
                                <InfoOutlined />
                              </MDButton>
                            </DataTableBodyCell>
                            <Dialog id={key} open={active === key} onClose={() => setActive(null)}>
                              <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                                <TableContainer sx={{ boxShadow: "none" }}>
                                  <Table>
                                    <MDBox component="thead">
                                      <DataTableHeadCell
                                        key={1}
                                        width={"auto"}
                                        align={"left"}
                                        sorted={false}
                                      >
                                        {"Numero Giornaliera"}
                                      </DataTableHeadCell>
                                      <DataTableHeadCell
                                        key={1}
                                        width={"auto"}
                                        align={"left"}
                                        sorted={false}
                                      >
                                        {"Autista"}
                                      </DataTableHeadCell>
                                      <DataTableHeadCell
                                        key={1}
                                        width={"auto"}
                                        align={"left"}
                                        sorted={false}
                                      >
                                        {"Filiale"}
                                      </DataTableHeadCell>
                                      <DataTableHeadCell
                                        key={1}
                                        width={"auto"}
                                        align={"left"}
                                        sorted={false}
                                      >
                                        {"Data"}
                                      </DataTableHeadCell>
                                    </MDBox>
                                    <TableBody>
                                      {value.map((row, key) => {
                                        return (
                                          <TableRow key={key}>
                                            <DataTableBodyCell
                                              noBorder={data.length - 1 === key}
                                              align={"left"}
                                            >
                                              {row.number}
                                            </DataTableBodyCell>
                                            <DataTableBodyCell
                                              noBorder={data.length - 1 === key}
                                              align={"left"}
                                            >
                                              {row.driverId}
                                            </DataTableBodyCell>
                                            <DataTableBodyCell
                                              noBorder={data.length - 1 === key}
                                              align={"left"}
                                            >
                                              {row.warehouseId}
                                            </DataTableBodyCell>
                                            <DataTableBodyCell
                                              noBorder={data.length - 1 === key}
                                              align={"left"}
                                            >
                                              {new Date(row.dateTime).toLocaleDateString()}
                                            </DataTableBodyCell>
                                          </TableRow>
                                        );
                                      })}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </MDBox>
                            </Dialog>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </MDBox>
            </Dialog>
          </MDBox>
      }

    </Card>
  );
}

export default OpenDriverWorkLists;