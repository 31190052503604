import ndjsonStream from "can-ndjson-stream";


export async function cachedRestCall(requestUrl, method, body, queryParams, refetch) {

  let queryString = "";
  if (queryParams) {

    const keys = Object.keys(queryParams);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = queryParams[key];

      let filterString = key.toString();
      if (queryString.length > 0) {
        queryString += `&`;
      }
      queryString += `${filterString}=${value}`;
    }
  }

  let key = "/" + queryString;

  const cache = await caches.open(requestUrl);
  const returnValue = await cache.match(key);

  if (returnValue?.body) {
    const ndjson = ndjsonStream(returnValue.body);
    const reader = ndjson.getReader();
    let result = [];
    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        break;
      }
      result = [...result, value];
    }
    reader.releaseLock();

    return result[0];
  }

  if (refetch || !returnValue) {
    let API_KEY = "";

    let requestOptions = {
      method: method,
      headers: {
        "Authorization": "Bearer " + API_KEY,
        "Content-Type": "application/x-ndjson",
        "Accept": "application/x-ndjson"
      },
      body: body ? JSON.stringify(body) : null
    };

    if (0 < queryString.length) {
      requestUrl += "?" + queryString;
    }

    return fetch(requestUrl, requestOptions)
      .then(checkStatus)
      .then(async response => {

        if (response.body) {
          const ndjson = ndjsonStream(response.body);
          const reader = ndjson.getReader();
          let result = [];
          while (true) {
            const { done, value } = await reader.read();
            if (done) {
              break;
            }
            result = [...result, value];
          }
          reader.releaseLock();

          await cache.put(key, new Response(JSON.stringify(result)));

          return result;
        }

      });
  }

}

export function restCall(requestUrl, method, body, queryParams, operatorApiKey) {


  let queryString = "";
  if (queryParams) {

    const keys = Object.keys(queryParams);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = queryParams[key];

      let filterString = key.toString();
      if (queryString.length > 0) {
        queryString += `&`;
      }
      queryString += `${filterString}=${value}`;
    }
  }

  let requestOptions = {
    method: method,
    headers: {
      "Content-Type": "application/x-ndjson",
      "Accept": "application/x-ndjson"
    },
    body: body ? JSON.stringify(body) : null
  };

  if (operatorApiKey) {
    requestOptions.headers["X-OPERATOR-API-KEY"] = operatorApiKey;
  }

  if (0 < queryString.length) {
    requestUrl += "?" + queryString;
  }

  return fetch(requestUrl, requestOptions)
    .then(checkStatus);

}

function checkStatus(response) {
  if (response.ok) {
    return response;
  }
  // convert non-2xx HTTP responses into errors:
  const error = new Error(response.statusText);
  error.response = response;
  return Promise.reject(error);
}